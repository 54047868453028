export const priceList = [
    {
        title : 'Инструктор ЛФК, массажист',
        priceList : [
            {
                name : 'Курс ЛФК',
                price : 90000
            },
            {
                name : 'Курс ЛФК',
                price : 75000
            },
            {
                name : 'ЛФК',
                price : 5000
            },
            {
                name : 'Мануальная терапия',
                price : 10000
            },
            {
                name : 'Мануальная терапия (репозиция костей таза и позвонков) Максутов М.А.',
                price : 50000
            },
            {
                name : 'Массаж головы (1 сеанс)',
                price : 3000
            },
            {
                name : 'Массаж грудного отдела позвоночника (1 сеанс )',
                price : 5000
            },
            {
                name : 'Массаж ног (1 сеанс)',
                price : 4000
            },
            {
                name : 'Массаж поясничного отдела позвоночника (1 сеанс)',
                price : 5000
            },
            {
                name : 'Массаж рук (1 сеанс)',
                price : 4000
            },
            {
                name : 'Массаж спины (1 сеанс)',
                price : 7000
            },
            {
                name : 'Массаж шейно-воротниковой зоны (1 сеанс)',
                price : 5000
            },
            {
                name : 'Реабилитация на тренажере RED CORD (1 занятие)',
                price : 5000
            }
        ]
    },
    {
        title : 'Медсестра процедурного кабинета',
        priceList : [
            {
                name : 'Внутривенная инъекция',
                price : 1000
            },
            {
                name : 'Внутривенное вливание',
                price : 2000
            },
            {
                name : 'Внутримышечная инъекция',
                price : 700
            },
            {
                name : 'Забор крови',
                price : 700
            },
            {
                name : 'Подкожная инъекция',
                price : 500
            },
            {
                name : 'Электрокардиограмма',
                price : 2000
            }
        ]
    },
    {
        title : 'Нейрохирург',
        priceList : [
            {
                name : 'Блокада каждого последующего корешка',
                price : 5000
            },
            {
                name : 'Блокада бедренного и запирательного нерва (тазобедренного сустава) под рентген-навигацией',
                price : 40000
            },
            {
                name : 'Блокада геникулярных нервов (нервы коленного сустава) под рентген-навигацией',
                price : 40000
            },
            {
                name : 'Блокада капсулы крестцово-подвздошного сочленения под рентген-навигацией',
                price : 40000
            },
            {
                name : 'Блокада капсулы тазобедренного, коленного сустава под рентген-навигацией',
                price : 40000
            },
            {
                name : 'Блокада непарного ганглия (кокцигодиния, хроническая тазовая боль)под рентген-навигацией',
                price : 40000
            },
            {
                name : 'Блокада симпатического, подчревного ганглия под рентген-навигацией',
                price : 60000
            },
            {
                name : 'Импульсная радиочастотная абляция корешков пояснично-крестцового отдела спинного мозга под рентген-навигацией (каждого последующего корешка).',
                price : 30000
            },
            {
                name : 'Импульсная радиочастотная абляция корешков шейного и грудного отдела спинного мозга под рентген-навигацией (1 корешок).',
                price : 150000
            },
            {
                name : 'Импульсная радиочастотная абляция корешков шейного и грудного отдела спинного мозга под рентген-навигацией (каждого последующего корешка).',
                price : 30000
            },
            {
                name : 'Нейрохирургическая медикаментозная блокада большого, малого+III затылочного нерва',
                price : 40000
            },
            {
                name : 'Нейрохирургическая медикаментозная блокада под УЗИ-навигацией',
                price : 15000
            }
        ]
    }
]