import './intro.scss'
import { Link, Route, Routes } from "react-router-dom"
import spine from './img/spine.svg'
import surgery from './img/surgery.svg'
import needle from './img/needle.svg'
import bones from './img/bones.svg'
import { Footer } from '../footer/footer'

export function Intro() {
    return (
        <>
            <main className="intro-main">
                <Routes>
                    <Route path="1" element={<Intro1 />} />
                    <Route path="2" element={<Intro2 />} />
                    <Route path='3' element={<Intro3 />} />
                    <Route path='4' element={<Intro4 />} />
                    <Route path='5' element={<Intro5 />} />
                    <Route path='6' element={<Intro6 />} />
                </Routes>
                <a href="https://wa.me/+77052727775" className="IntroBtn" target='_blank' rel="noreferrer">Записаться</a>
                <div className='intro-links'>
                    <Link to='1'>1</Link>
                    <Link to='2'>2</Link>
                    <Link to='3'>3</Link>
                    <Link to='4'>4</Link>
                    <Link to='5'>5</Link>
                    <Link to='6'>6</Link>
                </div>
            </main>
            <Footer />
        </>
    )
}

function Intro1() {
    return (
        <>
            <section className='intro-section'>
                <div className='info'>
                    <h2>Трепанобиопсии позвонков при опухолях и неуточненных воспалительных заболеваниях:</h2>
                    <p><span>Биопсия позвоночника</span> - это процедура, при которой образцы пораженной костной ткани извлекаются 
                        (с помощью специальной иглы для биопсии или во время операции), чтобы выяснить, присутствуют ли 
                        раковые или другие патологические клетки. Есть 2 вида биопсии: Пункционная биопсия. После 
                        применения местного анестетика врач прокалывает кожу специальной иглой для биопсии и проводит ее 
                        в вашу кость, чтобы получить образец. Открытая биопсия. После применения общего наркоза врач делает 
                        разрез на коже и хирургическим путем удаляет кусочек кости.</p>
                    <img src={surgery} alt="" className='mobile intro-img' />
                    <div>
                        <h2>Зачем мне может понадобиться биопсия позвоночника?</h2>
                        <p>Биопсия может быть проведена, если необходимо:</p>
                        <p>1. Оценить патологию, замеченную на снимке </p>
                        <p>2. Провести дифференциальную диагностику между опухолью кости: злокачественная она или нет. </p>
                        <p>3. Найти причину необъяснимой инфекции или воспаления.</p>
                        <p>4. Другие причины для рекомендации проведения биопсии кости.</p>
                    </div>
                </div>
                <div className='indications'>
                    <img src={surgery} alt="" className='intro-img'/>
                </div>
            </section>
        </>
    )
}

function Intro2() {
    return (
        <>
            <section className='intro-section'>
                <div className='info'>
                    <h2>Вертебропластика и кифопрастика при переломах и опухолях позвонков:</h2>
                    <p> <span>Вертебропластика</span> является эффективной и малоинвазивной методикой восстановления 
                        поврежденных позвонков, 
                        которое выполняется путём ввода в него специального «костного цемента». 
                        Благодаря этой операции люди с компрессионными переломами 
                        позвонков могут вернуться к полноценной жизни и 
                        избавиться от постоянных болей уже через 2 часа после операции.
                        Суть методики заключается в том, что хирург вводит в тело повреждённого 
                        позвонка металлическую иглу, через 
                        которую под флюороскопическим контролем вводится специальная смесь. </p>
                    <div className='mobile'>
                        <h2>Показания:</h2>
                        <p>
                            Агрессивная гемангиома позвоночника (доброкачественная сосудистая опухоль, которая поражает позвонки); <br /><br />
                            Компрессионные переломы позвонков (возникшие вследствие травмы, остеопороза); <br /><br />
                            Патологическое разрушение позвонка, вызванное опухолями различного генеза и метастазами. <br />
                        </p>
                        <img src={spine} alt="" />
                    </div>
                    <div>
                        <h2>Преимущества перкутанной вертебропластики:</h2>
                        <ol>
                            <li>Высокая эффективность. Целенаправленное и тщательно контролируемое введеие костного 
                            цемента позволяет эффективно укрепить позвонок и восстановить целостность повреждённой кости;</li>
                            <li>Малая инвазивность. Позволяет обеспечить минимальную травматичность процедуры, поскольку 
                            хирургические манипуляции не требуют выполнения масштабных операционных разрезов;</li>
                            <li>Использование местной анестезии. Это делает процедуру доступной для пациентов любого
                                возраста и с любым состоянием здоровья, поскольку местное обезболивание не даёт 
                                настолько большой нагрузки на организм, как имеющая место при общей анестезии;</li>
                            <li>Небольшое количество осложнений. Данный момент является 
                                следствием использования современных приборов и методик, что позволяет избежать 
                                повреждения прилежащих к позвонку структур вводимой иглой. 
                                Пункционный метод существенно снижает риск инфицирования, объём кровопотери, 
                                а также степень травматизации мышц и прочих мягких тканей;</li>
                            <li>Ранняя активизация пациентов. Современная методика проведения вертебропластики позволяет 
                                больным активно передвигаться всего через 
                                несколько часов после хирургического вмешательства.</li>
                            <li>Сокращение сроков пребывания пациента в стационаре. После проведения операции пациенту 
                                необходимо оставаться в больнице всего один день.</li>
                        </ol>
                    </div>
                </div>
                <div className='indications'>
                    <h2>Показания:</h2>
                    <p>Агрессивная гемангиома позвоночника (доброкачественная сосудистая опухоль, которая поражает позвонки);</p>
                    <p>Компрессионные переломы позвонков (возникшие вследствие травмы, остеопороза);</p>
                    <p>Патологическое разрушение позвонка, вызванное опухолями различного генеза и метастазами.</p>
                    <img src={spine} alt="" />
                </div>
            </section>
        </>
    )
}

function Intro3 () {
    return (
        <>
            <section className='intro-section'>
                <div className='info'>
                    <h1>Радиочастотная абляция или денервация нервных структур позвоночника (РЧА или РЧД)</h1>
                    <p><span>Радиочастотная абляция или денервация нервных структур позвоночника (РЧА или РЧД)</span> - 
                        представляет собой наиболее современный и инновационный, максимально безопасный малоинвазивный 
                        метод лечения хронического болевого синдрома, широко применяемый в самых современных клиниках 
                        лечения боли в мире. Методика обеспечивает длительный эффект, что обусловлено блокированием 
                        передачи сигнала от места возникновения боли до коркового анализатора боли в головном мозге. 
                        Процедура не имеет возрастных ограничений и характеризуется высокой эффективностью</p>
                    <div className='mobile'>
                        <img src={needle} alt="" className='intro-img'/>
                        <h1>Показания к проведению радиочастотной абляции:</h1>
                        <ul>
                            <li>Спондилоартроз с фасеточным синдромом, проявляющийся болью в пояснице, болью в спине, болью в шейном и грудном отделах позвоночника.</li>
                            <li>Головная боль и затылочная невралгия, связанные с фасеточным болевым синдромом.</li>
                            <li>Боли в тазобедренном, коленном, плечевом суставах, не купируемые медикаментозной терапией.</li>
                            <li>Межреберная невралгия.</li>
                            <li>Радикулопатия, в том числе после удаления грыжи диска.</li>
                            <li>Кокцигодиния.</li>
                            <li>Хроническая тазовая боль.</li>
                            <li>Неврома Мортона.</li>
                            <li>Отсутствие эффекта от консервативной терапии.</li>
                            <li>Положительный эффект от тестовой селективной блокады.</li>
                        </ul>
                    </div>
                    <div>
                        <h1>Преимущества радиочастотной абляции:</h1>
                        <ol>
                            <li>Продолжительность манипуляции всего 20-30 минут.</li>
                            <li>Местная анестезия.</li>
                            <li>Возможность проведения процедуры в амбулаторных условиях или в режиме дневного стационара 
                                (в том числе в нашей клинике в рамках ОСМС).</li>
                            <li>Безопасность.</li>
                            <li>Высокая эффективность.</li>
                            <li>Возможность повторного проведения абляции.</li>
                        </ol>
                    </div>
                </div>
                <div className='indications'>
                    <img src={needle} alt="" className='intro-img' />
                    <h1>Показания к проведению радиочастотной абляции:</h1>
                    <ol>
                        <li>Спондилоартроз с фасеточным синдромом, проявляющийся болью в пояснице, болью в спине, болью в шейном и грудном отделах позвоночника.</li>
                        <li>Головная боль и затылочная невралгия, связанные с фасеточным болевым синдромом.</li>
                        <li>Боли в тазобедренном, коленном, плечевом суставах, не купируемые медикаментозной терапией.</li>
                        <li>Межреберная невралгия.</li>
                        <li>Радикулопатия, в том числе после удаления грыжи диска.</li>
                        <li>Кокцигодиния.</li>
                        <li>Хроническая тазовая боль.</li>
                        <li>Неврома Мортона.</li>
                        <li>Отсутствие эффекта от консервативной терапии.</li>
                        <li>Положительный эффект от тестовой селективной блокады.</li>
                    </ol>
                </div>
            </section>
        </>
    )
}

function Intro4 () {
    return (
        <>
            <section className='intro-section'>
                <div className='info'>
                    <h1>ВТЭС-ТЕРАПИЯ ПО ГЕРАСИМОВУ</h1>
                    <p><span>ВТЭС-ТЕРАПИЯ ПО ГЕРАСИМОВУ </span>– это современный немедикаментозный метод лечения различных болевых 
                        синдромов при помощи низкочастотного тока, подаваемого к надкостнице непосредственно в очаге боли.</p>
                    <div>
                        <h1>Преимущества метода:</h1>
                        <ol>
                            <li>Воздействует непосредственно на пораженную ткань, минуя кожный покров и мышцы.</li>
                            <li>Практически полное отсутствие противопоказаний и побочных эффектов.</li>
                            <li>Быстрое снятие болевого синдрома (2-3 процедуры), при общей 
                                продолжительности курса лечения до 5 - 10 процедур.</li>
                            <li>Стойкий продолжительный эффект лечения, снижение частоты обострений в 2-3 раза.</li>
                            <li>Хорошо сочетается с другими немедикаментозными и 
                                безоперационными методами лечения сложных заболеваний 
                                опорно-двигательного аппарата - протрузий и 
                                грыж межпозвоночных дисков, сокращает срок лечения в 2-3 раза.</li>
                            <li>Восстанавливает микроциркуляцию крови в пораженных 
                                участках опорно-двигательного аппарата и стимулирует восстановление костных клеток.</li>
                            <li>Предотвращает разрушение тканей суставов, 
                                способствует восстановлению хрящевой ткани, межпозвонковых дисков, надкостницы и связок.</li>
                            <li>Высокая эффективность: устраняет боль у 90% пациентов.</li>
                        </ol>
                    </div>
                    <div className='mobile'>
                        <h1 className='not-margin-top'>Показания :</h1>
                        <ol>
                            <li>Боль в различных отделах позвоночника и опорно-двигательного аппарата</li>
                            <li>Остеохондроз, протрузии и грыжи дисков, прострелы в пояснице.</li>
                            <li>Боли в конечностях, онемение пальцев</li>
                            <li>Артрозы крупных суставов, плече-лопаточный периартроз</li>
                            <li>Поражения периферических нервов, невропатии, невралгии</li>
                            <li>Головная боль, мигрень, шум в ушах, головокружение</li>
                            <li>Хронические нарушения мозгового кровообращения, и последствия перенесенных инсультов, снижение памяти и когнитивных функций мозга</li>
                            <li>Травмы опорно-двигательного аппарата</li>
                            <li>Пяточная шпора и т.д.</li>
                        </ol>
                    </div>
                </div>
                <div className='indications'>
                    <h1 className='not-margin-top'>Показания :</h1>
                    <ol>
                        <li>Боль в различных отделах позвоночника и опорно-двигательного аппарата</li>
                        <li>Остеохондроз, протрузии и грыжи дисков, прострелы в пояснице.</li>
                        <li>Боли в конечностях, онемение пальцев</li>
                        <li>Артрозы крупных суставов, плече-лопаточный периартроз</li>
                        <li>Поражения периферических нервов, невропатии, невралгии</li>
                        <li>Головная боль, мигрень, шум в ушах, головокружение</li>
                        <li>Хронические нарушения мозгового кровообращения, и последствия перенесенных инсультов, снижение памяти и когнитивных функций мозга</li>
                        <li>Травмы опорно-двигательного аппарата</li>
                        <li>Пяточная шпора и т.д.</li>
                    </ol>
                </div>
            </section>
        </>
    )
}


function Intro5 () {
    return (
        <>
            <section className='intro-section'>
                <div className='info'>
                    <h1>Проведение блокад аутоплазмой (PRP-терапия) под рентген и УЗИ навигацией</h1>
                    <p><span>Проведение блокад аутоплазмой (PRP-терапия) под рентген и УЗИ навигацией</span> – Блокада аутологичной 
                        тромбоцит-активированной плазмой (плазмотерапия, плазмолифтинг) — это новый метод лечения 
                        неврологических заболеваний и заболеваний суставов, а также ряда других, связанных с необходимостью 
                        регенерации тканей. Также этот метод известен как PRP технология. Аббревиатура PRP образована от 
                        англоязычного названия методики platelet rich plasma, что переводится как «плазма, обогащенная 
                        тромбоцитами».</p>
                    <div>
                        <h1>В ЧЕМ ПРЕИМУЩЕСТВА МЕТОДА?</h1>
                        <p><span>PRP-терапия имеет существенные преимущества перед 
                            классическими методами лечения болей в позвоночнике:</span></p>
                        <ol>
                            <li>Она не просто устраняет симптомы, а воздействует на причину патологии. 
                                Факторы роста способствуют восстановлению и заживлению межпозвоночного диска.</li>
                            <li>Метод помогает пациентам, которые долго безуспешно 
                                лечились с помощью НПВС, блокад и физиопроцедур.</li>
                            <li>После курса лечения достигает долгосрочный эффект, 
                                в то время как обезболивающие препараты действуют лишь относительно короткое время.</li>
                            <li>Метод отличается высокой безопасностью и имеет минимум противопоказаний. 
                                Ведь в качестве лекарства используют собственную плазму пациента. 
                                Она не оказывает на организм негативных эффектов, не вызывает аллергических реакций.</li>
                            <li>Некоторым пациентам благодаря применению PRP-терапии 
                                удается избежать хирургических вмешательств на позвоночнике.</li>
                            <li>Положительный эффект наступает довольно быстро. 
                                Обычно пациент чувствует заметное улучшение в течение 4–6 недель.</li>
                        </ol>
                    </div>
                    <div className='mobile'>
                        <h1>Показания к применению PRP-терапии:</h1>
                        <ol>
                            <li>Остеохондроз позвоночника</li>
                            <li>Артриты и деформирующие артрозы</li>
                            <li>Спортивные травмы</li>
                            <li>Туннельные синдромы</li>
                            <li>Резорбция костной ткани</li>
                            <li>Тендиниты</li>
                            <li>Реабилитация после операций, протезирования или травм</li>
                            <li>Болезненные спазмы мышц шеи, спины, ног</li>
                            <li>Пяточная шпора и т.д</li>
                        </ol>   
                    </div>
                </div>
                <div className='indications'>
                    <img src={bones} alt="" className='intro-img' />
                    <h1>Показания к применению PRP-терапии:</h1>
                    <ol>
                        <li>Остеохондроз позвоночника</li>
                        <li>Артриты и деформирующие артрозы</li>
                        <li>Спортивные травмы</li>
                        <li>Туннельные синдромы</li>
                        <li>Резорбция костной ткани</li>
                        <li>Тендиниты</li>
                        <li>Реабилитация после операций, протезирования или травм</li>
                        <li>Болезненные спазмы мышц шеи, спины, ног</li>
                        <li>Пяточная шпора и т.д</li>
                    </ol>
                </div>
            </section>
        </>
    )
}

function Intro6() {
    return (
        <>
            <div className='intro-6-container'>
                <h1>Проведение нейрохирургических лечебных блокад под рентген и УЗИ навигацией</h1>
                <p><span>Блокада</span> – это специальная инъекция, позволяющий провести диагностику и лечение различных 
                    болевых синдромов. Состав блокады: для диагностической тестовой блокады чаще достаточно 
                    использования местного анестетика, а при проведении блокады с лечебной целью используется 
                    стероидный гормон в микродозе и местный анестетик. Точность и безопасность процедуры обеспечивается 
                    тем, что в нашей клинике все блокады проводятся под рентген-навигацией или под УЗИ-контролем.</p>
                <p>Нейрохирургические медикаментозные блокады помогают не только в диагностике, 
                    но и оказывают лечебное действие, позволяющее во многих случаях избежать оперативного 
                    вмешательства. Блокады при грыже позвоночника считаются эффективными в 95% случаев 
                    лечения боли при остеохондрозе, радикулите, грыже и протрузии межпозвоночного диска. 
                    Это обусловлено тем, что укол делается непосредственно в место давления грыжи диска на 
                    нервный корешок. В результате очень быстро снимается боль в спине и пациент чувствует улучшение. 
                    Процедура не требует госпитализации и занимает не более 20 минут. В нашей клинике врачи-нейрохирурги 
                    практикуют проведение самых различных видов блокад, дающих возможность проведения безоперационных 
                    методов лечения различных заболеваний позвоночника и суставов. </p>
            </div>
        </>
    )
}