import { Footer } from '../footer/footer'
import './advertisement.scss'
import backPain from './img/backPain.svg'
import backMassage from './img/massage.svg'
import qualification from './img/qualification.svg'
import runningGirl from './img/runningGirl.svg'

export function Advertisement() {
    return (
        <>
            <main className="advertisement-main">
                <h1 className='main-text'>Избавься от грыжи и боли в спине за 1 день при помощи уникальной 
                Израильской технологии, 
                    которая стала доступной в Алмате.</h1>
                <section className='advertisement-section'>
                    <div className='text-container'>
                        <h1>Почему секретная Израильская технология работает лучше традиционных методов?</h1>
                        <p>Ранее до 2022 года хирургия (операция) была основным методом борьбы с этим состоянием. <br /><br /> 

                        Но наши врачи прошли сверхрезультативную подготовку в Израиле по самым действенным 
                        методам лечения, БЕЗ ПРИМЕНЕНИЯ операции. <br /> <br />

                        Такая методика в отличии от других методик приносят гораздо меньше боли. <br /> <br />

                        А еще – Израильская методика восстанавливает функции позвоночника и облегчает симптомы воспаления. <br /> <br />

                        Но самое главное – это сокращение времени восстановления. Отсутствие необходимости 
                        в хирургическом вмешательстве снижает риски послеоперационных осложнений и позволяет 
                        пациентам быстрее вернуться к нормальной активной жизни. <br /> <br />

                        </p>
                    </div>
                    <div className='img-container'>
                        <img src={backPain} className='intro-img' alt="" id='backpain' />
                    </div>
                </section>
                <section className='advertisement-section'>
                    <div className='img-container'>
                        <img src={backMassage} alt="" className='intro-img second-and-third-img' />
                        <img src={qualification} alt="" className='intro-img second-and-third-img' />
                    </div>
                    <div className='text-container'>
                        <h1>3 причины пройти лечение у нас</h1>
                        <p><span>1. Неоперативные методы.</span><br />
                        По статистике – 92% клиник г. Алматы лечат при помощи операций. 
                        Наши же врачи успешно помогают пациентам НЕ ПРИБЕГАЯ к операциям. <br /><br />

                        Такое возможно за счет современной методики – регенеративной медицины. <br /><br />

                        Она включает в себя проведение прицельных блокад под рентген-навигацией, 
                        плазмотерапии под УЗИ-навигацией, физиопроцедур, проводимых непосредственно на 
                        надкостнице позвонков и суставов. И метод радиочастотного прогревания больного нерва 
                        спинного позвонка, что позволяет “перезагрузить” систему проведения болевой импульсации и 
                        тем самым снять боль, не прибегая к хирургическому вмешательству. 
                        </p>
                        <p>
                            <span>2. 100% выздоровление</span><br /><br />

                            И это не шутка. <br /> <br />

                            Дело в том, что мы используем Израильскую методику, 
                            которая по всему миру является номер 1 по лечению грыжи позвоночника, 
                            протрузии, остеохондроза и боли в спине без операции. <br /><br />

                            Из всех наших пациентов мы оперируем только 5%, остальные 95% обходятся без операции! <br /><br />

                            Поэтому наши методы позволяют добиваться высоких результатов даже в случаях средней и 
                            высокой сложности.
                        </p>
                        <p>
                            <span>3. Безопасность</span><br /><br />

                            За все 5 лет работы не было ни одного негативного случая. <br /><br />

                            Все потому что по Израильской методике вначале избавляем вас от боли, 
                            и только потом лечим причину возникновения боли. 
                            При таком подходе лечение проходит гладко
                        </p>
                    </div>
                </section>
                <section className='advertisement-section'>
                    <div className='text-container'>
                        <h1>Верните себе активность и движения в жизни.</h1>
                        <p>
                        Запишитесь на диагностику.<br /><br />

                        Диагностика помогает :<br /><br />
                        1. Установить диагноз. <br /><br />
                        2. Назначить правильный курс лечения.<br /><br />
                        5. Позвоните по номеру [ваш номер] и получите консультацию врача-специалиста бесплатно!<br /><br />
                        6. Большой штат врачей-нейрохирургов обученных по израильской методике.<br /><br />
                        *фотографии врачей
                        7. Отзывы наших клиентов <br /><br />

                        Посмотрите на отзывы людей не страдающих от боли
                        (скрины переписок и видео отзывы)<br /><br />
                        8. Вопросы/Ответы<br /><br />

                        9. Записывайтесь до конца лета для получение бесплатной консультации и разбор снимка МРТ!<br /><br />
                        </p>
                    </div>
                    <div className='img-container'>
                        <img src={runningGirl} alt="" className='intro-img' id='runninggirl'/>
                    </div>
                </section>
                <a href="https://wa.me/+77052727775" className='advertisement-btn'>Записаться</a>
            </main>
            <Footer />
        </>
    )
}