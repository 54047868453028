export const secondPriceList = [
    {
        title : 'Нейрохирург',
        priceList : [
            {
                name : `Нейрохирургические медикаментозные блокады фасеточных 
                суставов или медиальных ветвей корешков поясничного отдела позвоночника 
                под рентген-навигацией (до 4-х точек).`,
                price : 40000
            },
            {
                name : `Нейрохирургические медикаментозные блокады фасеточных 
                        суставов или медиальных ветвей корешков шейного, 
                        грудного отдела позвоночника под рентген-навигацией (до 4-х фасеток).`,
                price : 40000
            },
            {
                name : `Нейрохирургические медикаментозные блокады фасеточных суставов поясничного отдела позвоночника 
                под рентген-навигацией (до 6-ти точек).`,
                price : 40000
            },
            {
                name : `Нейрохирургические медикаментозные блокады: трансфораминальная блокада корешков спинного мозга 
                поясничного отдела (1 корешок).`,
                price : 40000
            },
            {
                name : `Нейрохирургические медикаментозные блокады: 
                трансфораминальная блокада корешков спинного мозга шейного отдела (1 корешок).`,
                price : 40000
            },
            {
                name : `Плазмолифтинг позвонков и суставов (1 анатомическая зона)`,
                price : 30000
            },
            {
                name : `Радиочастотная абляция геникулярных нервов (коленного сустава) под рентген-навигацией.`,
                price : 150000
            },
            {
                name : `Радиочастотная абляция капсулы крестцово-подвздошного сочленения под рентген-навигацией.`,
                price : 150000
            },
            {
                name : `Радиочастотная абляция медиальных ветвей корешков пояснично-крестцового отдела позвоночника 
                под рентген-навигацией (до 4-х точек).`,
                price : 120000
            },
            {
                name : `Радиочастотная абляция медиальных ветвей корешков пояснично-крестцового отдела позвоночника 
                под рентген-навигацией (до 6-ти точек).`,
                price : 150000
            },
            {
                name  : `Радиочастотная абляция медиальных ветвей корешков шейного и грудного отдела позвоночника под рентген-навигацией (до 4-х точек).`,
                price : 150000
            },
            {
                name : `Радиочастотная абляция медиальных ветвей корешков шейного и 
                грудного отдела позвоночника под рентген-навигацией (до 6-ти точек).`,
                price : 180000
            },
            {
                name : `Сакральная эпидуральная блокада по Катлину под рентген-навигацией`,
                price : 40000
            },
            {
                name : `Трансламинарная эпидуральная блокада  поясничного отдела под рентген-навигацией`,
                price : 40000
            }
        ]
    },
    {
        title : 'Нейрохирург, невропатолог',
        priceList : [
            {
                name : 'Первичная консультация специалиста',
                price : 10000
            },
            {
                name : 'Повторная консультация специалиста (в течение 1 месяца)',
                price : 7000
            }
        ]
    },
    {
        title : 'Нейрохирург, реабилитолог, невропатолог',
        priceList : [
            {
                name : 'ВТЭС терапия по методу Герасимова А.А. (1 процедура)',
                price : 6000
            },
            {
                name : 'Озонотерапия',
                price : 5000
            }
        ]
    },
    {
        title : 'Нутрициолог',
        priceList : [
            {
                name : 'Месячное ведение пациента врачом-нутрициологом',
                price : 70000
            },
            {
                name : 'Первичная консультация превентивного врача- нутрициолога',
                price : 20000
            },
            {
                name : 'Повторная консультация превентивного врача- нутрициолога',
                price : 10000
            }
        ]
    }
]