export const workersData = [
    {
        name : 'Айтмухамбетова Айнур Аманжоловна',
        services : 'Консультация специалиста, ВТЭС, озонотерапия',
        profession : 'Врач невропатолог, нутрициолог',
        price : {first : 10000, repeat : 7000}
    },
    {
        name : 'Сетиев Назим Майдинулы',
        services : 'ЛФК, массаж, УВТ',
        profession : 'Инструктор ЛФК, массажист',
    },
    {
        name : 'Есенбаев Ергали Тлектесович',
        services : 'Консультация специалиста, ВТЭС, озонотерапия',
        profession : 'Врач невролог',
        price : {first : 10000, repeat : 7000}
    },
    {
        name : 'Камирдинов Сайпидин Савирдинович',
        services : 'Консультация специалиста, ВТЭС, озонотерапия, плазмолифтинг, РЧА, блокада, нейрохирургические операции',
        profession : 'Врач нейрохирург',
        price : {first : 15000, repeat : 8000}
    },
    {
        name : 'Мырзалиев Нурканат Копжанович',
        services : 'Озонотерапия',
        profession : 'Врач анестезиолог-реаниматолог'
    },
    {
        name : 'Сатбаев Султан Заманканович',
        services : 'Консультация специалиста, ВТЭС, озонотерапия, плазмолифтинг, РЧА, блокада, нейрохирургические операции',
        profession : 'Врач нейрохирург',
        price : {first : 10000, repeat : 7000}
    },
    {
        name : 'Сопруненко Владимир Геннадьевич',
        services : 'Консультация специалиста',
        profession : 'Врач нейрохирург',
        price : {first : 15000, repeat : 8000},
        promotion : 0
    },
    {
        name : 'Турапов Раймжан Адылович',
        services : 'ЛФК, массаж',
        profession : 'Массажист'
    },
    {
        name : 'Муканбаева Балгын Кудайбергеновна',
        services : 'Консультация специалиста, ВТЭС, физиопроцедуры',
        profession : 'Врач реабилитолог, физиотерапевт'
    },
    {
        name : 'Мустафинов Дархан Бауыржанович',
        services : 'Консультация специалиста, плазмолифтинг, РЧА, блокада, нейрохирургические операции',
        profession : 'Врач нейрохирург',
        price : {first : 10000, repeat : 7000},
        promotion : 0
    },
    {
        name : 'Татьянникова Татьяна Алексеевна',
        services : 'Консультация специалиста, плазмолифтинг, РЧА, блокада, нейрохирургические операции',
        profession : 'Врач нейрохирург',
        price : {first : 10000, repeat : 7000},
        promotion : 0
    }
]